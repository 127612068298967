class Router
{
  routes = {
    '':           './home/HomeController.js',
    '/admin':     './admin/AdminController.js',
    '/login':     './login/LoginController.js'
  };

  constructor() {
    let path = document.location.pathname.replace('', '');

    path = path.replace(/\/$/g, '');
    let modulePath = null;
    for (let p of Object.keys(this.routes)) {
      let regexStr = p;
      regexStr = regexStr.replace(/(\w+_)?:id/g, '[-\\d]+');
      regexStr = regexStr.replace(/:\w+/g, '[-\\w]+');
      let regex = new RegExp(`^${ regexStr }$`);
      if (regex.exec(path) !== null) {
        modulePath = this.routes[p];
        break;
      }
    }

    if (modulePath === null) {
      return;
    }

    // Not just import(modulePath), it weirdly causes “Cannot find module”
    import(`${ modulePath }`).then((module) => {
      new module.default();
    }).catch((err) => {
      console.warn('Cannot load controller: ', err);
    });

    return;
  }
}

export { Router };
